<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AuthDropdown',
  data: function () {
    return {
      error: null,
      response: null,
    };
  },
  methods: {
    ...mapActions('auth', ['userLoggedOut']),
    logout: function () {
      this.userLoggedOut();
      this.$auth.signOut().then(() => this.$router.push({ name: 'loggedOut' }).catch(() => undefined));
    },
  },
  computed: {
    ...mapGetters('auth', ['displayName', 'hasPermission', 'isAdmin', 'isDeveloper', 'isMasqueraded']),
  },
};
</script>

<template>
  <b-dropdown :variant="isMasqueraded ? 'danger' : 'light'" no-caret right>
    <template v-slot:button-content>
      <i
        :class="{
          ['icon-user']: true,
          ['text-danger']: !isMasqueraded && !isDeveloper && isAdmin,
          ['text-warning']: !isMasqueraded && isDeveloper,
          ['text-info']: isMasqueraded,
        }"
      ></i>
      <span class="ml-2 d-none d-sm-inline text-lowercase">{{ displayName }}</span>
    </template>
    <b-dropdown-group v-if="isDeveloper">
      <b-dropdown-header>Developer Tools</b-dropdown-header>
      <b-dropdown-item @click="navigate('AuthCredentials')">OAuth Credentials</b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-group v-if="isAdmin || isDeveloper">
      <b-dropdown-header>Admin Tools</b-dropdown-header>
      <b-dropdown-item @click="navigate('AdminPasswordReset')">Password Reset</b-dropdown-item>
      <b-dropdown-item @click="navigate('AdminResendWelcomeEmail')">Resend Welcome Email</b-dropdown-item>
      <b-dropdown-item v-if="hasPermission('admin.user.masquerade')" @click="navigate('AdminUserMasquerade')">
        User Masquerade
      </b-dropdown-item>
    </b-dropdown-group>
    <b-dropdown-divider v-if="isDeveloper"></b-dropdown-divider>
    <b-dropdown-item @click="logout"><i class="icon-switch2"></i> Logout</b-dropdown-item>
  </b-dropdown>
</template>
