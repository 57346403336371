<template>
  <div id="app">
    <VOffline @detected-condition="handleConnectivityChange"> </VOffline>
  </div>
</template>

<script>
import VOffline from 'v-offline';

export default {
  name: 'Connectivity',
  components: { VOffline },
  data() {
    return {
      onLine: null,
    };
  },
  methods: {
    handleConnectivityChange() {
      if (!navigator.onLine) {
        this.toast('b-toaster-bottom-left', {
          body: 'You are offline and may have difficulty sending us information. Please check your internet connection',
          title: 'No Connection!',
          variant: 'danger',
        });
        this.onLine = false;
      } else if (this.onLine === false && navigator.onLine === true) {
        this.toast('b-toaster-bottom-left', {
          body: 'You are now online and ready to send us information',
          title: 'Connection Made',
          variant: 'info',
        });
        this.onLine = true;
      }
    },
    toast(toaster, options, append = false) {
      this.$bvToast.toast(options.body, {
        title: options.title,
        toaster: toaster,
        variant: options.variant,
        solid: true,
        appendToast: append,
      });
    },
  },
};
</script>
